import { StatusDescription } from "~~/stores/status.store"

export const DEFAULT_TICKET_STATUSES = [StatusDescription.NEW, StatusDescription.PROCESSING]
export const DEFAULT_DEBOUNCE_TIME = 500
export const DEFAULT_PAGE_SIZE = 50
export const DEFAULT_PAGE_SIZE_OPTIONS = [20, 50, 100, 200, 500, -1]

export const PAGE_PADDING_XS = 16
export const PAGE_PADDING_SM_AND_UP = 32

/**
 * We don't want to query for specific records if the selection is too large
 * CORS will block the request if the URL is too long
 * tested with 200 ids and it worked
 */
export const MAX_QUERY_ID_SELECTION_COUNT = 200

export const GPS_X_MIN = -90
export const GPS_X_MAX = 90
export const GPS_Y_MIN = -180
export const GPS_Y_MAX = 180

export const RD_X_MIN = -7000
export const RD_X_MAX = 300000
export const RD_Y_MIN = 289000
export const RD_Y_MAX = 629000
